<template>
  <el-main>
    <div class="topTips">
      <div>此为跨境电商PC网站的装修部分，PC端显示英文，请下面内容添加英文</div>
      <div v-if="website_url">
        网站地址：
        <span @click="toclickWeb">{{ website_url }}</span>
      </div>
    </div>
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
      <el-form-item label="官网名称：" prop="name">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="官网logo：" prop="logo">
        <div class="uploadLogo">
          <ReadyUploadSource
            :path="ruleForm.logo"
            @getSource="val => (ruleForm.logo = val.path)"
            @removeThis="ruleForm.logo = ''"
            :showStyle="{
              width: '200px',
              height: '60px',
            }"
          ></ReadyUploadSource>
          <div class="sizeTips">建议图片尺寸200*60px，大小不超过1M</div>
        </div>
      </el-form-item>
      <el-form-item label="网站ico图标：" prop="ico">
        <div class="uploadIco">
          <div v-if="ruleForm.ico" class="icoShow">
            <img :src="ruleForm.ico" alt="" />
            <i class="el-icon-error delete" @click="ruleForm.ico = ''"></i>
          </div>
          <div class="icoBox" v-else>
            <i class="el-icon-plus"></i>
            <input ref="icoInput" type="file" accept=".ico" @change="getIcoFile" />
          </div>
          <div class="sizeTips">图片尺寸16*16px，大小不超过16kb</div>
        </div>
      </el-form-item>
      <el-form-item label="官网banner：" prop="banner">
        <div class="uploadLogo">
          <ReadyUploadSource
            :isMany="true"
            :manyPath="ruleForm.banner"
            @changeOrder="list => (ruleForm.banner = list)"
            @getSource="list => ruleForm.banner.push(...list)"
            @removeThis="index => ruleForm.banner.splice(index, 1)"
            :showStyle="{
              width: '384px',
              height: '120px',
            }"
          ></ReadyUploadSource>
          <div class="sizeTips">建议图片尺寸1920*600px，大小不超过1M</div>
        </div>
      </el-form-item>
      <el-form-item label="联系邮箱：" prop="email">
        <el-input v-model="ruleForm.email"></el-input>
      </el-form-item>
      <el-form-item label="版权信息：" prop="version_info">
        <el-input v-model="ruleForm.version_info"></el-input>
      </el-form-item>
      <el-form-item label="公司logo：" prop="company_logo">
        <div class="uploadLogo">
          <ReadyUploadSource
            :path="ruleForm.company_logo"
            @getSource="val => (ruleForm.company_logo = val.path)"
            @removeThis="ruleForm.company_logo = ''"
            :showStyle="{
              width: '100px',
              height: '100px',
            }"
          ></ReadyUploadSource>
          <div class="sizeTips">建议图片尺寸200*200px，大小不超过1M</div>
        </div>
      </el-form-item>
      <el-form-item label="公司名称：" prop="company_name">
        <el-input v-model="ruleForm.company_name"></el-input>
      </el-form-item>
      <el-form-item label="公司简介：" prop="company_desc">
        <el-input v-model="ruleForm.company_desc"></el-input>
      </el-form-item>
    </el-form>
    <Preservation @preservation="Preservation"></Preservation>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
import ReadyUploadSource from '@/components/readyUploadSource';
import _ from 'lodash';
export default {
  components: {
    Preservation,
    ReadyUploadSource,
  },
  data() {
    function checkLogo(rule, value, callback) {
      if (!value) {
        callback(new Error('请上传官网logo'));
      } else {
        callback();
      }
    }
    function checkIco(rule, value, callback) {
      if (!value) {
        callback(new Error('请上传官网ico图标'));
      } else {
        callback();
      }
    }
    function checkBanner(rule, value, callback) {
      if (!value.length) {
        callback(new Error('请上传官网banner'));
      } else {
        callback();
      }
    }
    function checkCompanyLogo(rule, value, callback) {
      if (!value) {
        callback(new Error('请上传公司logo'));
      } else {
        callback();
      }
    }
    return {
      website_url: '',
      ruleForm: {
        name: '',
        logo: '',
        ico: '',
        banner: [],
        email: '',
        version_info: '',
        company_logo: '',
        company_name: '',
        company_desc: '',
      },
      rules: {
        name: [{ required: true, message: '请输入官网名称', trigger: 'blur' }],
        email: [{ required: true, message: '请输入联系邮箱', trigger: 'blur' }],
        version_info: [{ required: true, message: '请输入版权信息', trigger: 'blur' }],
        company_name: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
        company_desc: [{ required: true, message: '请输入公司简介', trigger: 'blur' }],
        logo: [{ required: true, validator: checkLogo, trigger: 'change' }],
        ico: [{ required: true, validator: checkIco, trigger: 'change' }],
        banner: [{ required: true, validator: checkBanner, trigger: 'change' }],
        company_logo: [{ required: true, validator: checkCompanyLogo, trigger: 'change' }],
      },
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    toclickWeb() {
      window.open(this.website_url);
    },
    Preservation() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let obj = _.cloneDeep(this.ruleForm);
          this.$axios.post(this.$api.Tikmall.setBaseInfo, obj).then(res => {
            if (res.code === 0) {
              this.$message.success('设置成功');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    getInfo() {
      this.$axios.post(this.$api.Tikmall.getInfo).then(res => {
        if (res.code === 0) {
          if (res.result.info) {
            for (const key in this.ruleForm) {
              this.ruleForm[key] = res.result.info[key];
              if (key === 'banner' && res.result.info[key]) this.ruleForm[key] = JSON.parse(res.result.info[key]);
            }
            this.website_url = res.result.info.website_url;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    async getIcoFile() {
      if (!this.$refs.icoInput.files.length) return;
      let file = this.$refs.icoInput.files[0];
      let suffix = file.name.substr(file.name.lastIndexOf('.')).toLowerCase();
      if (suffix !== '.ico' || file.size > 1024 * 1024 * 16) {
        this.$message.warning('请上传格式为ico,尺寸为16*16px,大小不超过16k的图片');
        return;
      }
      let url = window.URL || window.webkitURL;
      let img = new Image(); //手动创建一个Image对象
      img.src = url.createObjectURL(file); //创建Image的对象的url
      let { width, height } = await new Promise(resolve => {
        img.onload = function () {
          resolve({ width: img.width, height: img.height });
        };
      });
      if (width !== 16 || height !== 16) {
        this.$message.warning('请上传格式为ico,尺寸为16*16px,大小不超过16k的图片');
        return;
      }
      let formData = new FormData();
      formData.append('ico', file);
      this.$axios.uploadFile(this.$api.upload.addIco, formData).then(res => {
        if (res.code === 0) {
          this.$message.success('上传成功');
          this.ruleForm.ico = res.result.path;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  min-height: 100%;
  background-color: #fff;
  font-size: 14px;
  padding-bottom: 80px;
  .topTips {
    width: 100%;
    background-color: #f2f2f2;
    padding: 25px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      color: #1466fd;
      cursor: pointer;
    }
    span:hover {
      text-decoration: underline;
    }
  }
  .el-form {
    margin-top: 20px;
    .el-input {
      width: 500px;
    }
    .sizeTips {
      color: #999999;
      font-size: 12px;
    }
    .uploadIco {
      .icoShow {
        width: 80px;
        height: 80px;
        position: relative;
        border: 1px dashed #c0ccda;
        img {
          width: 100%;
          height: 100%;
        }
        &:hover {
          .delete {
            display: block !important;
          }
        }
        .delete {
          display: none;
          font-size: 24px !important;
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(50%, -50%);
          cursor: pointer;
        }
      }
      .icoBox {
        width: 80px;
        height: 80px;
        border: 1px dashed #c0ccda;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        i {
          font-size: 28px;
          color: #8c939d;
        }
        input {
          width: 100%;
          height: 100%;
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
</style>
