var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-main',[_c('div',{staticClass:"topTips"},[_c('div',[_vm._v("此为跨境电商PC网站的装修部分，PC端显示英文，请下面内容添加英文")]),(_vm.website_url)?_c('div',[_vm._v(" 网站地址： "),_c('span',{on:{"click":_vm.toclickWeb}},[_vm._v(_vm._s(_vm.website_url))])]):_vm._e()]),_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.ruleForm,"status-icon":"","rules":_vm.rules,"label-width":"120px"}},[_c('el-form-item',{attrs:{"label":"官网名称：","prop":"name"}},[_c('el-input',{model:{value:(_vm.ruleForm.name),callback:function ($$v) {_vm.$set(_vm.ruleForm, "name", $$v)},expression:"ruleForm.name"}})],1),_c('el-form-item',{attrs:{"label":"官网logo：","prop":"logo"}},[_c('div',{staticClass:"uploadLogo"},[_c('ReadyUploadSource',{attrs:{"path":_vm.ruleForm.logo,"showStyle":{
            width: '200px',
            height: '60px',
          }},on:{"getSource":function (val) { return (_vm.ruleForm.logo = val.path); },"removeThis":function($event){_vm.ruleForm.logo = ''}}}),_c('div',{staticClass:"sizeTips"},[_vm._v("建议图片尺寸200*60px，大小不超过1M")])],1)]),_c('el-form-item',{attrs:{"label":"网站ico图标：","prop":"ico"}},[_c('div',{staticClass:"uploadIco"},[(_vm.ruleForm.ico)?_c('div',{staticClass:"icoShow"},[_c('img',{attrs:{"src":_vm.ruleForm.ico,"alt":""}}),_c('i',{staticClass:"el-icon-error delete",on:{"click":function($event){_vm.ruleForm.ico = ''}}})]):_c('div',{staticClass:"icoBox"},[_c('i',{staticClass:"el-icon-plus"}),_c('input',{ref:"icoInput",attrs:{"type":"file","accept":".ico"},on:{"change":_vm.getIcoFile}})]),_c('div',{staticClass:"sizeTips"},[_vm._v("图片尺寸16*16px，大小不超过16kb")])])]),_c('el-form-item',{attrs:{"label":"官网banner：","prop":"banner"}},[_c('div',{staticClass:"uploadLogo"},[_c('ReadyUploadSource',{attrs:{"isMany":true,"manyPath":_vm.ruleForm.banner,"showStyle":{
            width: '384px',
            height: '120px',
          }},on:{"changeOrder":function (list) { return (_vm.ruleForm.banner = list); },"getSource":function (list) {
                    var ref;

                    return (ref = _vm.ruleForm.banner).push.apply(ref, list);
},"removeThis":function (index) { return _vm.ruleForm.banner.splice(index, 1); }}}),_c('div',{staticClass:"sizeTips"},[_vm._v("建议图片尺寸1920*600px，大小不超过1M")])],1)]),_c('el-form-item',{attrs:{"label":"联系邮箱：","prop":"email"}},[_c('el-input',{model:{value:(_vm.ruleForm.email),callback:function ($$v) {_vm.$set(_vm.ruleForm, "email", $$v)},expression:"ruleForm.email"}})],1),_c('el-form-item',{attrs:{"label":"版权信息：","prop":"version_info"}},[_c('el-input',{model:{value:(_vm.ruleForm.version_info),callback:function ($$v) {_vm.$set(_vm.ruleForm, "version_info", $$v)},expression:"ruleForm.version_info"}})],1),_c('el-form-item',{attrs:{"label":"公司logo：","prop":"company_logo"}},[_c('div',{staticClass:"uploadLogo"},[_c('ReadyUploadSource',{attrs:{"path":_vm.ruleForm.company_logo,"showStyle":{
            width: '100px',
            height: '100px',
          }},on:{"getSource":function (val) { return (_vm.ruleForm.company_logo = val.path); },"removeThis":function($event){_vm.ruleForm.company_logo = ''}}}),_c('div',{staticClass:"sizeTips"},[_vm._v("建议图片尺寸200*200px，大小不超过1M")])],1)]),_c('el-form-item',{attrs:{"label":"公司名称：","prop":"company_name"}},[_c('el-input',{model:{value:(_vm.ruleForm.company_name),callback:function ($$v) {_vm.$set(_vm.ruleForm, "company_name", $$v)},expression:"ruleForm.company_name"}})],1),_c('el-form-item',{attrs:{"label":"公司简介：","prop":"company_desc"}},[_c('el-input',{model:{value:(_vm.ruleForm.company_desc),callback:function ($$v) {_vm.$set(_vm.ruleForm, "company_desc", $$v)},expression:"ruleForm.company_desc"}})],1)],1),_c('Preservation',{on:{"preservation":_vm.Preservation}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }